.faq-footer {
  /* background-color: #0e1227; */
  color: white;
  padding: 40px 20px;
  font-family: Arial, sans-serif;
}

.faq-section {
  display: flex;
  justify-content: center;
  flex-direction: row;
  max-width: 1200px;
  margin: 0 auto 60px;
}

.faq-description {
  flex: 1;
  max-width: 300px;
  margin-right: 40px;
}

.faq-description h2 {
  font-size: 2.5em;
}

.faq-description p {
  font-size: 1.2em;
}

.faq-description a {
  color: #007bff;
  text-decoration: none;
}

.faq-description a:hover {
  text-decoration: underline;
}

.faqs {
  flex: 2;
  border-top: 1px solid #333;
}

.faq {
  border-bottom: 1px solid #333;
}

.faq-question {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}

.faq-question span:last-child {
  font-size: 1.5em;
}

.faq-answer {
  padding: 10px 0 20px;
  font-size: 1em;
  color: #aaa;
}

.footer {
  background: url("../assets/HeroBg.svg");
  background-size: cover;
  color: white;
  padding: 40px 20px;
  /* position: relative; */
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
}

.footer-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 0;
  border-top: 1px solid #333;
  border-bottom: 1px solid #333;
}

.footer-logo {
  font-size: 1.5em;
  font-weight: bold;
  margin-right: 10px;
}

.footer-separator {
  margin: 0 10px;
}

.footer-links a {
  color: white;
  margin: 0 10px;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}

.footer-bottom span {
  font-size: 0.8em;
  color: #aaa;
}

.footer-bottom-links a {
  color: #aaa;
  margin: 0 10px;
  text-decoration: none;
}

.footer-bottom-links a:hover {
  text-decoration: underline;
}

.footer-icons a {
  color: white;
  margin: 0 5px;
  font-size: 1.2em;
}

.footer-icons a:hover {
  color: #007bff;
}

/* .footer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../assets/HeroBg.svg");
  background-size: cover;
  opacity: 0.5;
  z-index: -1;
} */

@media (max-width: 767px) {
  .faq-section {
    flex-direction: column;
    padding: 24px;
  }
  .faq-description{
    max-width: fit-content;
  }
  .footer-logo {
    font-size: 1rem;
    /* position: relative; */
  }
  .footer-top{
    gap: 30px;
  }
  .footer-bottom-links{
    display: flex;
  }
}
