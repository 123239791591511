/* src/components/Hero.css */
#hero {
  position: relative;
  z-index: 1;
  text-align: center;
  color: white;
  padding: 150px 20px;
  overflow: hidden;
}

.hero-background {
  position: absolute;
  top: 100px;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../assets/HeroBg.svg"); /* Ensure the image path is correct */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
}

.hero-content {
  margin-bottom: 50px;
  position: relative;
  top: -50px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero-content h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
}

.hero-content p {
  font-size: 1.2em;
  margin-bottom: 20px;
}
.hero h6 {
  color: #9ea3bf;
}
.cta-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1em;
}

/* .new-version {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9em;
  margin-bottom: 20px;
} */

.dashboard-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.translucent-tab {
  position: absolute;
  top: -30px;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  width: 60px;
  height: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 2;
}

.translucent-tab .dot {
  width: 8px;
  height: 8px;
  background-color: #ccc;
  border-radius: 50%;
}

.dashboard-img {
  width: 80%;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 2;
}
#hero {
  color: #9ea3bf;
}
