/* General styles */
header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 1rem;
  background-color: #05071a;
  color: white;
  position: relative;
  z-index: 10; /* Ensure it stays on top */
}

nav {
  display: flex;
  align-items: center;
}

nav a {
  margin: 0 1rem;
  color: white;
  text-decoration: none;
}

nav a:hover {
  color: #007bff;
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: white;
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

/* Ensure nav links are aligned properly */
.nav-links {
  display: flex;
  align-items: center; /* Align vertically */
  gap: 20px;
}

.nav-links a {
  font-size: 1.2rem;
}

.navbar-button {
  padding: 10px 20px !important;
  background-color: #1f2937;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Adjust for mobile view */
@media only screen and (max-width: 1024px) {
  /* Show hamburger button */
  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  /* Hide nav by default in mobile */
  .nav-links {
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    background-color: #05071a;
    height: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    transition: height 0.3s ease-in-out;
    z-index: 10;
    padding: 10px;
  }

  /* When nav is open, it should display as a dropdown */
  .nav-links.open {
    height: auto; /* Adjust height based on the number of links */
  }

  .nav-links a {
    color: white;
    padding: 1rem 0;
  }

  /* Close button inside the menu */
  .nav-links .nav-close-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 1.8rem;
  }

  /* Align nav items properly */
  nav a {
    font-size: 1.2rem;
  }

  /* Ensure the alignment of nav button and close button */
  header {
    justify-content: space-between;
  }

  /* Align items vertically in mobile view */
  nav {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
