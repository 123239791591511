/* Base styles */
.choose-plan {
    text-align: center;
    color: white;
    padding: 60px;
  }
  
  h1 {
    font-size: 2.5em;
  }
  
  p {
    font-size: 1.2em;
    margin-bottom: 20px;
  }
  
  .toggle-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
  }
  
  .toggle-switch {
    border-radius: 25px;
    cursor: pointer;
    display: inline-block;
    height: 30px;
    margin: 0 10px;
    position: relative;
    width: 60px;
  }
  
  .toggle-knob {
    background-color: #fff;
    border-radius: 50%;
    height: 26px;
    left: 2px;
    position: absolute;
    top: 2px;
    transition: transform 0.2s;
    width: 26px;
  }

  .toggle-switch.monthly-bg {
    background-color: #1b1c2c; /* Monthly background color */
  }
  
  .toggle-switch.yearly-bg {
    background-color:#171d40; /* Yearly background color */
  }
  .toggle-knob.yearly {
    transform: translateX(30px);
  }
  
  .plans {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
    justify-content: space-between;
    gap: 20px; /* Add space between items */
  }
  
  .plan {
    box-shadow: 0px -10px 40px 0px #101636 inset;
    border: 1px solid #1C244C;
    background: #05071A;
    width: 100%; /* Default to full width */
    max-width: 384px; /* Max width for larger screens */
    height: auto;
    padding: 40px 24px;
    gap: 16px;
    border-radius: 32px;
    box-sizing: border-box; /* Ensure padding is included in width */
  }
  
  .plan h2 {
    font-size: 1.5em;
  }
  
  .plan p {
    color: #ccc;
  }
  
  .plan h3 {
    font-size: 2em;
  }
  
  .plan span {
    font-size: 0.8em;
    color: #aaa;
  }
  
  .plan ul {
    list-style-type: none;
    padding: 0;
  }
  
  .plan ul li {
    margin: 10px 0;
    color: #bbb;
  }
  
  .plan button {
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    padding: 10px 20px;
  }
  
  .plan:hover {
    background-color: #101636;
  }
  
  .plan.populared::before {
    background-color: #28a745;
    border-radius: 5px;
    color: white;
    /* content: 'Popular'; */
    padding: 5px 10px;
    position: absolute;
    right: 20px;
    top: 20px;
  }
  
  /* Responsive styles */
  @media (min-width: 1024px) {
    .plans {
      flex-direction: row;
      justify-content: space-between;
    }
  
    .plan {
      flex: 1 1 calc(33.333% - 20px); /* 3 items in a row with space */
    }
  }
  
  @media (max-width: 1023px) and (min-width: 768px) {
    .plans {
      flex-direction: column;
      align-items: center;
    }
  
    .plan {
      width: 80%;
      margin-bottom: 20px;
    }
  }
  
  @media (max-width: 767px) {
    .plans {
      flex-direction: column;
      align-items: center;
    }
  
    .plan {
      margin-bottom: 20px;
    }
  }
  