.testimonial-card {
  box-shadow: 0px -10px 40px 0px #101636 inset;
  border: 1px solid #1c244c;
  background: #05071a;
  width: auto;
  padding: 24px;
  border-radius: 32px;
  color: white;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.testimonial {
  font-size: 16px;
  opacity: 0.8;
  margin-bottom: 16px;
}

.testimonial-author {
  display: flex;
  align-items: center;
  border-top: 1px solid black;
  gap: 12px;
  justify-content: space-between;
}

.author-image {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
}

.author-name {
  font-size: 14px;
  font-weight: bold;
}

.author-title {
  font-size: 12px;
  opacity: 0.8;
}
.testimonial-app {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #05071a;
  padding: 40px;
  color: white;
  text-align: center;
}

.subtitle {
  font-size: 18px;
  opacity: 0.8;
  margin-bottom: 32px;
}

.testimonials {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
  margin: 0 auto;
}

@media (max-width: 992px) {
  .testimonials {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .testimonials {
    grid-template-columns: 1fr;
  }
}
