/* src/components/AboutUs.css */
#about-us {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: min-content;
  justify-content: center;
  padding: 130px 30px 120px;
  position: relative;
  z-index: 6;
  background-color: #05071a; 
  color: white;
}

.about-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 1200px;
  width: 100%;
}

#about-us h2 {
  font-size: 2em;
  margin-bottom: 20px;
}

.carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}

.cards-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 32px;
  overflow: hidden;
  width: 100%;
}

.dots {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.dot {
  height: 12px;
  width: 12px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.dot.active {
  background-color: #717171;
}
@media (max-width: 1023px) {
  .about-content {
    flex-direction: column;
  }
  #about-us{
    flex-direction: column;
  }
}

/* Mobile (767px and below) */
@media (max-width: 767px) {
  .about-content {
    flex-direction: column;
  }
  #about-us{
    flex-direction: column;
  }
  .cards-wrapper {
    display: grid; /* Use grid layout for responsive control */
    width: 100%;
    grid-template-columns: 1fr; /* 1 card per row on mobile */
    gap: 16px; /* Reduce gap for mobile */
  }
}