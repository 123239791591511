.card {
    box-shadow: 0px -10px 40px 0px #101636 inset;
    border: 1px solid #1C244C;
    background: #05071A;
    width: 384px; /* Fixed width */
    height: auto; /* Hug height */
    padding: 40px 24px;
    gap: 16px;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: white;
  }
  
  .card-icon {
    margin-bottom: 16px; /* Gap between icon and title */
  }
  
  .card-title {
    margin-bottom: 16px; /* Gap between title and description */
    font-size: 24px;
    font-weight: bold;
  }
  
  .card-description {
    font-size: 16px;
    opacity: 0.8;
  }
