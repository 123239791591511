/* src/components/TrustCompanies.css */

.trust-companies {
    text-align: center;
    margin: 20px;
  }
  
  .trust-text {
    font-size: 18px;
    color: rgb(158, 163, 191);
    margin-bottom: 20px;
    position: relative;
  }
  
  .trust-logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 60px; /* Space between images */
  }
  
  .trust-logos img {
    max-width: 100%;
    height: auto;
    /* Adjust height based on your needs */
  }
  
  /* Responsive Styles */
  
  /* For large screens */
  @media (min-width: 1024px) {
    .trust-logos img {
      width: 150px; /* Width for large screens */
      height: auto;
    }
  }
  
  /* For tablets (768px to 1023px) */
  @media (max-width: 1023px) {
    .trust-logos img {
      width: 120px; /* Slightly smaller width for tablets */
      height: auto;
    }
  }
  
  /* For mobile screens (767px and below) */
  @media (max-width: 767px) {
    .trust-logos img {
      width: 80px; /* Smaller width for mobile */
      height: auto;
    }
  }
  