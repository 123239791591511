.form-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    color: white;
  }
  
  .form-container h2 {
    margin-bottom: 10px;
  }
  
  .form-container p {
    margin-bottom: 20px;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    box-shadow: 0px -10px 40px 0px #101636 inset;
    border: 1px solid #1c244c;
    background: #05071a;
    padding: 40px 24px;
    border-radius: 32px;
    align-items: center;
    text-align: center;
    color: white;
    position: relative;
    overflow: hidden;
    margin: 20px 0;
    padding: 40px; /* Ensure there's enough padding inside the form */
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  
  .form-group label {
    margin-bottom: 5px;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #333;
    border-radius: 5px;
    background-color: #121835;
    color: #fff;
  }
  
  .form-group textarea {
    height: 100px;
  }
  
  .form button {
    padding: 10px;
    background-color: #3b82f6;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
  }
  
  .form button:hover {
    background-color: #2563eb;
  }
  
  /* Responsive Styles */
  @media (min-width: 600px) {
    .form {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 40px;
    }
  
    .form-group {
      width: auto;
    }
  
    .form-group:last-of-type {
      grid-column: span 2;
    }
  
    .form button {
      grid-column: span 2;
    }
  }
  
  @media (max-width: 600px) {
    .form {
      display: flex;
      flex-direction: column;
    }
  
    .form-group {
      width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .form-container {
      padding: 10px;
    }
  
    .form {
      padding: 20px;
    }
  
    .form button {
      padding: 10px;
    }
  
    .form-group input,
    .form-group textarea {
      padding: 8px;
    }
  }
  
  
  
  /* .transform-section {
    text-align: center;
    margin-top: 50px;
  }
  
  .transform-section h2 {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .transform-section p {
    margin-bottom: 20px;
    font-size: 1rem;
  }
  
  .get-started-button {
    padding: 15px 30px;
    background-color: #1e90ff;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .get-started-button:hover {
    background-color: #1c86ee;
  }
   */
  /* Responsive Styles */
  /* @media (max-width: 576px) {
    .demo-form-section h2,
    .transform-section h2 {
      font-size: 1.5rem;
    }
  
    .demo-form-section p,
    .transform-section p {
      font-size: 0.9rem;
    }
  
    .get-started-button {
      padding: 10px 20px;
      font-size: 0.9rem;
    }
  
    button[type="submit"] {
      padding: 10px;
      font-size: 0.9rem;
    }
  
    .form-group input,
    .form-group textarea {
      padding: 8px;
      font-size: 0.8rem;
    } */
  /* } */
  